@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

button[type="submit"] {
    @apply bg-sig-pink text-sig-purple hover:text-sig-pink hover:bg-sig-purple
 }

 @layer base {
 table {
    @apply w-full text-sm text-left rtl:text-right text-gray-500;
  }
  
  table thead {
    @apply text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700;
  }
  
  table th {
    @apply px-6 py-3;
  }
  
  table tbody tr {
    @apply bg-white border-b dark:bg-gray-800 hover:bg-gray-50;
  }

  table tbody tr th {
    @apply px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white;
  }
  
  table tbody tr td {
    @apply px-6 py-4;
  }

  table tbody tr td button {
    @apply font-medium text-sig-pink hover:text-sig-purple;
  }
}